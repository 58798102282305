import React, { useEffect } from 'react';
import './CustomOrderOffsite.css';  // Import a CSS file for styling (explained below)

const CustomOrderOffsite = ({ currency }) => {
  // Map countries to Stripe payment links
  const paymentLinks = {
    EUR: "https://buy.stripe.com/dR64hd1JQbJA4r6cMZ",
    USD: "https://buy.stripe.com/7sI3d9gEK9BsbTyaES",
    AUD: "https://buy.stripe.com/28o8xt0FM8xog9O7sH",
    GBP: "https://buy.stripe.com/28obJF9ciaFw6zecN2",
    NZD: "https://buy.stripe.com/6oEcNJbkq7tk5va5kw",
    SAR: "https://buy.stripe.com/00geVR2NU00S4r65kv",
    AED: "https://buy.stripe.com/7sI00X74aaFwf5K28i",
    SGD: "https://buy.stripe.com/28o00XgEK7tk6ze7sB",
    INR: "https://buy.stripe.com/7sI00XgEK9Bs9Lq9AS"
  };

  useEffect(() => {
    if (currency) {
      const paymentLink = paymentLinks[currency];
      setTimeout(() => {  // Add a delay for a smoother user experience
        if (paymentLink) {
          window.location.href = paymentLink;  // Redirect to the payment link
        } else {
          console.error(`No payment link found for currency: ${currency}`);
          window.location.href = paymentLinks['USD'];  // Fallback to USD if no link is found
        }
      }, 0);
    }
  }, [currency]);

  return (
    <div className="redirect-container">
      <div className="spinner"></div>  {/* Spinner animation */}
      <h2>Redirecting to Payment Page...</h2>
      <p>Please wait a moment.</p>
      <p>If the page does not redirect, <a href={paymentLinks[currency] || paymentLinks['USD']}>click here</a>.</p>
    </div>
  );
};

export default CustomOrderOffsite;
